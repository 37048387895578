import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import SiteContainer from "../components/site-container";

class NotFoundPage extends React.Component {
    render() {
        return (
            <SiteContainer>
            <Helmet>
                <title>Firstparty 404 - Content not found</title>
            </Helmet>
            <section className="pt-9 pb-9 pt-md-12 pb-md-12 bg-light">
                <div className="container">
                    <h1>Request not found</h1>
                    <p>
                    We're sorry{" "}
                    <span role="img" aria-label="Pensive emoji">
                        😔
                    </span>{" "}
                    we couldn't find what you were looking for.
                    <br />
                    <br />
                    Some ideas to get you on your way:
                    <ul>
                        <li>• Hit your back button,</li>
                        <li>• Try the navigation up top,</li>
                        <li>• View the <Link to="/">home page</Link>,</li>
                        <li>• Read the <Link to="/news/">latest news from Firstparty</Link>,</li>
                        <li>• Or <Link to="/contact/">contact us</Link>; how can we help?</li>
                        </ul>
                    </p>
                </div>
            </section>
            </SiteContainer>
        )
    }
}

export default NotFoundPage
